import * as AuthProxy from "../security/AuthProxy";

enum ProgramSource {
   ELIGIBILITY = "ELIGIBILITY",
   TRANSFORMED_CODE ="TRANSFORMED_CODE",
   PHARMACY_NETWORK = "PHARMACY_NETWORK",
   PRICING = "PRICING"
}

type programInformation = {
    programSource: ProgramSource;
    programParameters?: string[];
}

export type programInformations = {
    [name: string]: programInformation
}

export function getProgramUrl(programName: string, parameters?: string[], tenantId? :string): string | undefined {
    // get program informations
    // @ts-ignore
    const pgm = window['navigation'][programName];
    // @ts-ignore
    const env = window['environments'];

    let url = undefined;
    // return program url
    if (pgm?.programSource === ProgramSource.ELIGIBILITY){
        let urlParameters = '';
        const urlKeys: string[] = pgm?.programParameters;
        if (parameters && parameters.length && parameters.length === urlKeys.length) {
            // TODO - move to environments.json
            const envMap = {
                'RXDV1_DEV': 'SXCD1',
                'RXCL1': 'CCTA',
                'RXCL4': 'SXCA2',
                'RXCL3': 'SXCA5',
                'RXCL2': 'SXCA6'
                };
            const encodedParameters: string[] = parameters.map((value, index) => urlKeys[index] + '=' + encodeURIComponent(value as string));
            encodedParameters.unshift('pid=' + envMap[tenantId]);
            encodedParameters.push('mode=edit');
            urlParameters = encodedParameters.length > 0 ? ";" + encodedParameters.join(";") : "";
        }
        const domain = env.eligibility
        const path = pgm?.programDestination;
        url = `${domain}member/${path}${urlParameters}`;
    }

    if (pgm?.programSource === ProgramSource.TRANSFORMED_CODE){
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        if(process.env.REACT_APP_API_ENV == "local" || process.env.REACT_APP_API_ENV == "dev" || process.env.REACT_APP_API_ENV == "qa") {
            url = `${env.transformed}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        } else {
            url = `${process.env.REACT_APP_PRESCRIBER_URL}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        }
    }

    if (pgm?.programSource === ProgramSource.PHARMACY_NETWORK){
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        if(process.env.REACT_APP_API_ENV == "local" || process.env.REACT_APP_API_ENV == "dev" || process.env.REACT_APP_API_ENV == "qa") {
            url = `${env.pharmacyNetwork}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        } else {
            url = `${process.env.REACT_APP_PHARMACY_NETWORK_URL}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        }
    }

    if (pgm?.programSource === ProgramSource.PRICING){
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        if(process.env.REACT_APP_API_ENV == "local" || process.env.REACT_APP_API_ENV == "dev" || process.env.REACT_APP_API_ENV == "qa") {
            url = `${env.pricing}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        } else {
            url = `${process.env.REACT_APP_PHARMACY_NETWORK_URL}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        }
    }

    if (pgm?.programSource === undefined){
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        if(process.env.REACT_APP_API_ENV == "dev" || process.env.REACT_APP_API_ENV == "qa") {
            url = `${env.transformed}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        } else {
            url = `${process.env.REACT_APP_PRESCRIBER_URL}/program/${programName}${urlParameters}/?tenantId=${tenantId}`
        }
    }

    // FIXME later : manage program source with presto and eligibility
    return url;
}
